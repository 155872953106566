.section-three {
    padding: 60px 100px;
}

.section-header-container {
    text-align: left;
    margin: 40px 80px;
}

.section-header-text {
    max-width: 500px;
}

.card-container {
    /* border: 1px solid black; */
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1600px;
}

.card {
    /* border: 1px solid blue; */
    text-align: left;
    max-width: 220px;
}

.card-img {
    max-width: 220px;
    border: 2px solid black;
    border-radius: 8px;
}

.section-three-header {
    font-size: 38px;
    font-weight: 400;
    margin: 0;
}

@media only screen and (max-width: 899px) {
    .section-header-container {
        margin: 0;
    }
}

@media only screen and (max-width: 600px) {
    .card-container {
        flex-direction: column;
    }

    .card {
        max-width: 100%;
        margin-bottom: 60px;
    }

    .section-three {
        padding: 10px;
    }
}