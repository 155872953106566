.section-six-header {
    text-align: left;
    margin: 140px 150px 60px 150px;
    font-size: 38px;
    font-weight: 400;
}

.testimonial-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1600px;
    margin-bottom: 100px;
}

.testimonial-img {
    max-width: 320px;
}

@media only screen and (max-width: 600px) {
    .section-six-header {
        margin: 100px 10px 60px 10px;
        max-width: 200px;
    }

    .testimonial-img {
        margin-bottom: 60px;
    }

    .testimonial-container {
        justify-content: flex-start;
        margin-left: 4px;
    }
}