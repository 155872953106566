.section-eight {
    background: #FFBE24;
    padding: 60px 150px;
    display: flex;
    flex-direction: row-reverse;
}

.section-eight-header {
    text-align: left;
    /* margin-bottom: 60px; */
    font-size: 42px;
    font-weight: 300;
}

.sub-header {
    margin-bottom: 60px;
    text-align: left;
}

.submit-btn {
    border-radius: 8px !important;
    padding: 0px 15px !important;
    height: 40px !important;
    margin: 9px 0 !important;
    background: black !important;
    color: white !important;
    border: 1px solid black !important;
    font-weight: 600 !important;
    cursor: pointer;
}

.form-container {
    flex: 45%;
}

.img-container {
    flex: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-img {
    /* max-width: 600px; */
    width: 100%;
}

.contact-form {
    /* border: 1px solid black; */
    text-align: left;
}

.text-field {
    background: white !important;
    width: 80%;
    margin: 0px 0px 10px 0!important;
    border-radius: 6px;
}

.message-container {
    min-height: 80px;
}

.copyright {
    background: #FFBE24;
    margin: 0;
    padding: 40px 10px;
}

@media only screen and (max-width: 600px) {
    .section-eight-header {
        max-width: 200px;
    }

    .section-eight {
        padding: 10px;
        flex-direction: column;
    }

    .text-field {
        width: 100%;
    }
}