.section-one {
    margin: 100px 200px;
}

.section-container {
    /* border: 1px solid black; */
    display: flex;
    flex-wrap: wrap;
}

.first-half {
    flex: 40%;
    text-align: left;
    /* border: 1px solid blue; */
}

.tag-container {
    /* border: 1px solid black; */
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
}

.tag {
    display: flex;
    flex: 30%;
}

.tag-img {
    max-width: 40px;
}

.tag-text {
    margin: auto 10px;
}

.second-half {
    flex: 50%;
    /* border: 1px solid red; */
}

.app-img {
    max-width: 550px;
}

.action-btn-inverse {
    background: black !important;
    color: white !important;
    margin: 20px 0 70px 0 !important;
}

.action-btn-inverse:hover {
    background: #FFBE24 !important;
    color: black !important;
}

.section-one-header {
    font-size: 50px;
    margin: 0;
    font-weight: 400;
}

.section-one-sub-header {
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
}

@media only screen and (max-width: 899px) {
    .section-one {
        margin: 10px;
    }

    .app-img {
        max-width: 300px;
    }

    .tag-container {
        flex-direction: column;
    }

    .tag {
        margin: 10px 0px;
    }

    .second-half {
        margin-top: 20px;
    }
}