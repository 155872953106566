.header {
    /* j-todo: color */
    background: white;
    height: 102px;
}

.app-bar {
    display: flex;
    border: 1px solid lightgray;
    padding: 20px 60px 20px 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* j-todo: color */
    background: white;
    z-index: 2;
}

.logo-container {
    display: flex;
    flex: auto;
}

.logo {
    max-width: 60px;
    border-radius: 300px;
}

.logo-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nav-container {
    display: flex;
}

.mobile-nav-container {
    display: none;
}

.nav-btn {
    margin-right: 10px !important;
    color: black !important;
}

.nav-btn:hover {
    background: transparent !important;
    color: grey !important;
}

.first-nav-btn {
    margin-top: 20px;
}

.action-btn {
    border-radius: 8px !important;
    padding: 0px 15px !important;
    height: 40px !important;
    margin: 9px 0 !important;
    background: #FFBE24 !important;
    color: black !important;
    border: 1px solid black !important;
    font-weight: 600 !important;
}

.action-btn:hover {
    background: black !important;
    color: #FFBE24 !important;
}

@media only screen and (max-width: 899px) {
    .header {
        height: 82px;
    }

    .app-bar {
        padding: 10px 0;
    }

    .nav-container {
        display: none;
    }

    .mobile-nav-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80px;
    }
  }