.section-four {
    margin: 30px 160px;
}

.section-four-container {
    display: flex;
    flex-wrap: wrap;
}

.four-first-half {
    /* flex: 40%; */
    /* border: 1px solid red; */
    margin-right: 280px;
}

.four-second-half {
    /* flex: 40%; */
    text-align: left;
    max-width: 600px;
    margin-top: 200px;
    /* border: 1px solid blue; */
}

.four-tag-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
}

.chat-img {
    max-width: 580px;
}

.section-four-header {
    font-size: 38px;
    font-weight: 400;
    margin: 0;
}

.section-four-text {
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
}

@media only screen and (max-width: 899px) {
    .section-four {
        margin: 0px 10px;
    }

    .chat-img {
        max-width: 300px;
    }

    .tag-container {
        flex-direction: column;
    }

    .tag {
        margin: 10px 0px;
    }

    .four-second-half {
        margin-top: 40px;
    }
}