.section-five {
    margin-top: 160px;
}

.section-five-content {
     /* j-todo: original color */
     background: #FFD6EF;
     /* background: #E9C46A; */
     /* height: 400px; */
     padding: 200px 100px 200px 20px;
     display: flex;
     flex-direction: column;
     text-align: left;
}

.first-section {
    margin-left: 150px;
    max-width: 700px;
}

.first-section-header {
    font-size: 44px;
    font-weight: 400;
}

.divider {
    /* border: 1px solid blue; */
    height: 60px;
    position: relative;
    margin-bottom: -1px;
}

.custom-shape-divider-bottom-1682023199 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1682023199 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 82px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1682023199 .shape-fill {
    fill: #FFD6EF;
}

.leaders {
    display: flex;
    justify-content: space-between;
    margin: 20px 150px 0 150px;
}

.leader-img {
    height: 170px;
    width: 200px;
    border: 1px solid black;
    border-radius: 8px;
}

.brighten-up {
    background: white;
}

.leader-header {
    font-size: 16px;
    font-weight: 800;
    margin: 10px 0 0 0;
}

.leader-container {
    max-width: 300px;
}

.leader-link {
    text-decoration: none;
    color: black;
}

.leader-link:hover {
    color: grey;
}

.leader-detail {
    margin: 8px 0;
}

@media only screen and (max-width: 899px) {
    .section-five-content {
        padding: 60px 10px;
    }

    .first-section {
        margin-left: 2px;
    }

    .leaders {
        flex-direction: column;
        margin: 0px;
        margin-top: 40px;
    }

    .leader-container {
        margin-bottom: 60px;
    }
}