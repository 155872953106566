.section-two {
    /* border: 1px solid black; */
    margin-top: 80px;
}

.divider {
    /* border: 1px solid blue; */
    height: 60px;
    position: relative;
    margin-bottom: -1px;
}

/* https://www.shapedivider.app/ */
.custom-shape-divider-bottom-1681934803 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1681934803 svg {
    position: relative;
    display: block;
    width: calc(115% + 1.3px);
    height: 76px;
}

.custom-shape-divider-bottom-1681934803 .shape-fill {
    /* j-todo: original color */
    fill: #FFBE24;
    /* fill: #E9C46A; */
}

.section-two-content {
    /* j-todo: original color */
    background: #FFBE24;
    /* background: #E9C46A; */
    /* height: 400px; */
    padding: 200px 100px 200px 20px;
    display: flex;
}

.left-section {
    flex: 50%;
}

.left-text {
    font-size: 38px;
    font-weight: 400;
    max-width: 400px;
    margin: auto;
    text-align: left;
}

.right-section {
    flex: 50%;
}

.right-text {
    text-align: left;
    max-width: 500px;
    margin: auto;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
}

@media only screen and (max-width: 899px) {
    .section-two-content {
        flex-direction: column;
        padding: 60px 10px;
    }
}