.ad {
    background: #ffbe24;
    margin-top: -60px;
    padding: 40px 10px 100px 10px;
}

.ad-header {
    background: #fff;
    border: 1px solid #d3d3d3;
    left: 0;
    padding: 20px 60px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
}

.ad-header-content {
    display: flex;
    width: 230px;
    cursor: pointer;
}

.ad-logo {
    max-width: 60px;
}

.ad-header-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    margin-top: -2px;
}

.ad-content {
    margin-top: 160px;
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
}

.ad-img-container {
    /* width: 100%; */
    /* border: 1px solid blue; */
    /* text-align: center; */
    max-width: 900px;
    margin: 0 auto;
    /* border: 2px solid #d3d3d3; */
    /* border-radius: 8px; */
}

.ad-img {
    width: 98%;
    /* needed to offset weird gap below */
    margin-bottom: -5px;
    border-radius: 8px;
    border: 2px solid grey;
    filter: blur(14px);
}

.ad-link {
    text-decoration: none;
    color: black;
}

.ad-download-content {
    max-width: 600px;
    margin: 40px auto;
    padding: 40px 120px;
    text-align: center;
    /* background: #ffbe24; */
    background: white;
    border-radius: 8px;
    min-height: 120px;
    /* border: 1px solid grey; */
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.ad-download-text {
    font-size: 26px;
    margin-bottom: 20px;
}

.ad-download-btn {
    border: none;
    border-radius: 8px;
    background: black;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
}

.ad-download-ready {
    display: none;
}

.ad-form-label {
    font-size: 18px;
}

.ad-form-input {
    font-size: 18px;
    padding: 6px;
    line-height: 26px;
    margin-right: 6px;
    width: 50%;
}

.ad-download-link {
    text-decoration: none;
    color: white;
}

.ad-btn-disabled {
    cursor: not-allowed;
    opacity: .7;
}

.ad-link-disabled {
    cursor: not-allowed;
    pointer-events: none;
}

@media only screen and (max-width: 600px) {
    .ad-download-content {
        padding: 40px 10px;
    }

    .ad-download-text {
        font-size: 24px;
    }
}